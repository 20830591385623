import {
  ReviewAttributes,
  DocumentInterpretationResponse,
  TransactionResponse,
  DocumentResponse,
  STATUS_TYPES,
  StatusValues,
} from 'types/api-responses';
import {
  CheckmarkFilled16,
  InProgress16,
  CircleDash16,
  DocumentPdf16,
} from '@carbon/icons-react';
import { get } from 'lodash';
import { FC, useReducer } from 'react';
import { Tag, TooltipDefinition, Link } from 'carbon-components-react';
import { getReviewDetails } from 'lib/review-interpretation';
import { Date } from 'components/date';
import reviewStatusTypes from 'enums/review_status_types.json';
import { ImportedTag } from 'components/imported-tag';
import style from './row-details.module.scss';
import { renderTransactionType } from 'lib/ui';
import { CalculationDetails } from 'components/calculation-details';
import { HighlightRunsheetRowProps } from 'lib/types';
import { useInView } from 'react-intersection-observer';
import { useAtomValue } from 'jotai';
import { runsheetExpandWithCalculationDetails } from './atoms';

export const TransactionStatusTag = ({
  status,
}: {
  status?: StatusValues | null;
}) => {
  if (!status) return null;

  return (
    <Tag
      type={
        status === STATUS_TYPES['HBP']
          ? 'purple'
          : status === STATUS_TYPES['partialHBP']
          ? 'magenta'
          : status === STATUS_TYPES['inactive']
          ? 'gray'
          : 'green'
      }
      size={'sm'}
    >
      {status}
    </Tag>
  );
};

export const TooltipText = ({
  totalReviewed,
  reviewStatus,
  sectionsCount,
}: {
  totalReviewed: number;
  sectionsCount: number;
  reviewStatus: string;
}) => {
  return (
    <>
      <div className={style.tooltipContainer}>
        <h4 className={style.tooltipHeaderText}>Review Status</h4>
        <p className={style.tooltipStatusText}>{reviewStatus}</p>
        <p className={style.tooltipPromptText}>
          {totalReviewed} out of {sectionsCount} form sections reviewed
        </p>
      </div>
    </>
  );
};

export const ReviewCount = ({
  reviewAttributes,
}: {
  reviewAttributes: ReviewAttributes;
}) => {
  if (!reviewAttributes) {
    return null;
  }

  const { sectionsCount, totalReviewed, reviewStatus } =
    getReviewDetails(reviewAttributes);

  return (
    <>
      <TooltipDefinition
        className={style.toolTip}
        direction="top"
        tooltipText={
          <TooltipText
            totalReviewed={totalReviewed}
            reviewStatus={reviewStatus}
            sectionsCount={sectionsCount}
          />
        }
      >
        {reviewStatus === reviewStatusTypes.reviewed && (
          <CheckmarkFilled16 className={style.checkMarkFilled} />
        )}
        {reviewStatus === reviewStatusTypes.partial && (
          <InProgress16 className={style.inProgress} />
        )}
        {reviewStatus === reviewStatusTypes.pending && (
          <CircleDash16 className={style.circleDash} />
        )}
      </TooltipDefinition>
    </>
  );
};

export const DocumentTypeHeader = ({
  document,
}: {
  document: DocumentInterpretationResponse['document'];
}) => {
  const { bookType, documentType } = document;
  const divider = bookType && documentType ? ' | ' : '';
  return (
    <span className={style.documentType}>
      {bookType} {divider} {documentType}
    </span>
  );
};

type interpretationRowDate =
  | 'interpretationDate'
  | 'instrumentDate'
  | 'effectiveDate'
  | 'recordedDate'
  | 'supersedingDate'
  | 'fileDate'
  | 'updatedAt';

interface RowDateProps {
  item:
    | DocumentInterpretationResponse['document']
    | DocumentInterpretationResponse;
  attribute: interpretationRowDate;
}

export const RowDate: FC<RowDateProps> = ({ item, attribute, children }) => {
  const date = get(item, attribute);
  return date ? (
    <>
      <Date date={date} />
      {children}
    </>
  ) : null;
};

export const NestedDocument = ({
  document,
  href,
}: {
  document: Pick<
    DocumentResponse,
    'id' | 'volume' | 'page' | 'documentTitle' | 'documentType'
  >;
  href: string;
}) => {
  const volumePage = `${document.volume ? `Vol ${document.volume}` : ''} ${
    document.page ? `Pg ${document.page}` : ''
  }`.trim();

  return (
    <>
      <a href={href} target="_blank" rel="noreferrer">
        {
          <span className={style.icon}>
            <DocumentPdf16 height={14} />
          </span>
        }
        {document.documentType ?? 'Unknown'} ·{' '}
        {volumePage || document.documentTitle}
      </a>
    </>
  );
};

export const SupportedBy = ({
  documentReferences,
}: {
  documentReferences?: DocumentInterpretationResponse['supportedBy'];
}) => (
  <>
    {documentReferences?.length ? (
      <section>
        <h1 className={style.detailsTitle}>Supported by these documents</h1>
        <div className={style.supportedPanel}>
          {documentReferences.map((dr) => (
            <div key={dr.id} className={style.detailsDesc}>
              <NestedDocument document={dr.document} href={dr.document.href} />
            </div>
          ))}
        </div>
      </section>
    ) : null}
  </>
);

export const Supporting = ({
  documents,
}: {
  documents?: DocumentInterpretationResponse['supporting'];
}) => {
  return documents?.length ? (
    <>
      <section>
        <span className={style.detailsTitle}>
          Supporting these interpretations
        </span>
        <div className={style.supportingPanel}>
          {documents.map((doc) => (
            <NestedDocument
              document={doc}
              key={doc.id}
              href={doc.interpretationHref}
            />
          ))}
        </div>
      </section>
    </>
  ) : null;
};

const MAX_APPLICABLE_AREAS = 5;

export const Details = ({
  row,
  titleWorkspace,
  titleWorkspaceHref,
  referenceLocationHref,
  refsMap,
  setHighlightedRow,
  lastCalculationHref,
}: {
  row: DocumentInterpretationResponse;
  titleWorkspace?: number;
  titleWorkspaceHref?: string | null;
  referenceLocationHref?: string;
  lastCalculationHref?: string;
} & HighlightRunsheetRowProps) => {
  const { locationReferences, supportedBy, notes } = row;
  const shouldTruncate = locationReferences.length > MAX_APPLICABLE_AREAS;
  const [truncated, toggleTruncated] = useReducer((s) => !s, true);
  const { ref, inView } = useInView();
  const includeCalculationDetailsOnExpanded = useAtomValue(
    runsheetExpandWithCalculationDetails
  );

  return (
    <div className={style.detailsPanel}>
      <div className={style.transactionDetailsPanel}>
        <ImportedTag value={row.imported} />
        <Supporting documents={row.supporting} />
        <SupportedBy documentReferences={supportedBy} />
        {'topLeases' in row && row.topLeases.length ? (
          <section>
            <div className={style.leaseOverlapPanel}>
              <h4 className={style.leaseOverlapTitle}>
                Related Top leases ({row.topLeases.length})
              </h4>
              {row.topLeases.map((lease) => (
                <Link key={lease.id} href={lease.interpretationHref}>
                  {lease.name}
                </Link>
              ))}
            </div>
          </section>
        ) : null}
        {'subjectLeases' in row && row.subjectLeases.length ? (
          <section>
            <div className={style.leaseOverlapPanel}>
              <h4 className={style.leaseOverlapTitle}>
                Leases Subject to this Top Lease ({row.subjectLeases.length})
              </h4>
              {row.subjectLeases.map((lease) => (
                <Link key={lease.id} href={lease.interpretationHref}>
                  {lease.name}
                </Link>
              ))}
            </div>
          </section>
        ) : null}
        {locationReferences ? (
          <section>
            <h4 className={style.detailsTitle}>
              Applicable Areas ({locationReferences.length})
            </h4>
            <div className={style.applicablePanel}>
              {(truncated && shouldTruncate
                ? locationReferences.slice(0, MAX_APPLICABLE_AREAS)
                : locationReferences
              ).map((locationReference, i) => {
                return (
                  <p
                    className={style.applicableAreaList}
                    key={`${locationReference.location.referenceLocationName}-${i}`}
                  >
                    {locationReference.location.referenceLocationName}{' '}
                    {locationReference.location.quarterCalls.length
                      ? ` • ${locationReference.location.quarterCalls.join(
                          ', '
                        )}`
                      : null}{' '}
                    <span className={style.depth}>
                      ({locationReference.depthRangeName})
                    </span>
                  </p>
                );
              })}
            </div>
            {shouldTruncate && (
              <a
                onClick={() => toggleTruncated()}
                className={style.viewMoreBtn}
              >
                {truncated ? 'View more' : 'View less'}
              </a>
            )}
          </section>
        ) : null}
      </div>
      <section className={style.notesSection}>
        <h4 className={style.detailsTitle}>Notes</h4>
        <div className={style.notesPanel}>
          <p className={style.detailsDesc}>{notes ? notes : <em>None</em>}</p>
        </div>
        <div ref={ref} className={style.calculationDetailsPanel}>
          {typeof lastCalculationHref ===
          'undefined' ? null : lastCalculationHref === null ? (
            <>Please run calculation to see calculation details</>
          ) : titleWorkspace &&
            referenceLocationHref &&
            inView &&
            titleWorkspaceHref ? (
            includeCalculationDetailsOnExpanded ? (
              <CalculationDetails
                titleWorkspace={titleWorkspace}
                titleWorkspaceHref={titleWorkspaceHref}
                referenceLocationHref={referenceLocationHref}
                refsMap={refsMap}
                setHighlightedRow={setHighlightedRow}
                interpretationId={row.id}
                lastCalculationHref={lastCalculationHref}
              />
            ) : null
          ) : null}
        </div>
      </section>
    </div>
  );
};

export const TransactionType = ({ row }: { row: TransactionResponse }) => {
  return <>{renderTransactionType(row)}</>;
};
